import Image from "next/image";
import Link from "next/link";
import React from "react";

interface SocialMediaProps {
  src: string;
  className?: string;
}

const SocialMedia: React.FC<SocialMediaProps> = ({ src, className = "" }) => {
  return (
    <Image src={src} alt="" className={`w-full aspect-square ${className}`} />
  );
};

export default SocialMedia;
