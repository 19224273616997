"use client";
import ScrollToTopImg from "@/public/images/ScrollToTop.png";
import Image from "next/image";
import { useEffect, useState } from "react";

const ScrollToTop: React.FC = () => {
  const [isVisible, setIsVisible] = useState<boolean>(false);

  useEffect(() => {
    const toggleVisibility = (): void => {
      if (window.scrollY > 300) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener("scroll", toggleVisibility);

    return () => window.removeEventListener("scroll", toggleVisibility);
  }, []);

  const handleScrollToTop = (): void => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <>
      {isVisible && handleScrollToTop && (
        <Image
          alt="Scroll to top"
          src={ScrollToTopImg}
          className="fixed right-5 bottom-[30px] max-w-[50px] aspect-square cursor-pointer"
          onClick={handleScrollToTop}
        />
      )}
    </>
  );
};

export default ScrollToTop;
