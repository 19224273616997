"use client";
import React from "react";
import SocialMediaItem from "./units/SocialMediaItem";
import Link from "next/link";
import WhatsAppImg from "@/public/images/Whatsapp.png";
import MessengerImg from "@/public/images/Messenger.png";
import TelegramImg from "@/public/images/Telegram.png";
import dynamic from "next/dynamic";
import ScrollToTop from "../scrollToTop/ScrollToTop";
import MyLink from "../myLink/MyLink";
// const ScrollToTop = dynamic(() => import("./../scrollToTop/ScrollToTop"));

const SocialMedia: React.FC = () => {
  const items = [
    { image: WhatsAppImg, href: "https://wa.me/+201080556105" },
    {
      image: MessengerImg,
      href: "https://www.facebook.com/profile.php?id=61561661176951",
    },
    { image: TelegramImg, href: "https://t.me/eduvalu" },
  ];

  return (
    <>
      <section className="fixed left-5 bottom-[30px] flex flex-col max-w-[50px]">
        {items.map((el, index) => (
          <MyLink
            key={index}
            href={el.href}
            className="backdrop-blur-[5px]"
          >
            <SocialMediaItem
              key={index}
              src={el.image as unknown as string}
              className={index > 0 ? "mt-3.5" : ""}
            />
          </MyLink>
        ))}
      </section>
      <ScrollToTop />
    </>
  );
};

export default SocialMedia;
